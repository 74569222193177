<template>
  <main class="page">
    <div class="page__grid">
      <ProgressBar :percent="50" class="progress--header"></ProgressBar>

      <h1 class="page__header">Choose Images</h1>

      <p class="page__description">
        Please scroll through/view all pictures in this category you have
        chosen. Select those pictures that deeply resonate with you on an
        emotional basis. Clue – these are the picture/images that arrest your
        attention, and to which you find your attention irresistibly drawn to.
        For reasons of brevity and precision you can select 1-3 pictures per
        category chosen
      </p>

      <div class="step">
        <p class="step__text">Choose 3-5 categories</p>
        <div class="step__input">
          <div
            class="step__fields"
            v-for="(options, index) in filteredImages"
            :key="index"
          >
            <label class="step__fields__label">{{ deSlug(index) }}</label>
            <div class="field-flex">
              <div class="field" v-for="(option, i) in options" :key="i">
                <div class="field__wrap field__wrap-checkbox">
                  <div>
                    <input
                      class="field__checkbox"
                      type="radio"
                      :id="`${index}${i}`"
                      :name="`${index}`"
                      :value="option[1]"
                      autocomplete="false"
                      v-model="selectedImage[index]"
                      required
                    />
                    <label :for="`${index}${i}`"><span></span></label>
                  </div>
                  <label :for="`${index}${i}`" class="field__checkbox-image">
                    <img
                      :src="`/img/image/${option[1]}.jpg`"
                      width="100"
                      alt=""
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="step__btn">
          <router-link
            :to="{ name: 'Lessons' }"
            class="button button--secondary"
            >Go Back</router-link
          >
          <button
            @click="routeToNextPage"
            class="button button--primary"
            :disabled="!validateInput"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
    <div class="page__grid"></div>
  </main>
</template>

<script>
import storage from '@/utils/storage'
import ProgressBar from '@/components/ui/progress-bar'
import { deSlug } from '@/utils/convert'

export default {
  name: 'Images',
  components: { ProgressBar },
  data() {
    return {
      selectedImage: {
        legacy_and_trustees_of_family_history_and_values: '',
        compassion_and_social_impact_and_justice: '',
        encouragement: '',
        harmony_craftsmanship_and_entrepreneurship: '',
        contemplation_meaning_and_transcendence: '',
        breaking_limits_insurgency: '',
        next_gen_grandchildren: '',
        family_celebration_and_togetherness: '',
      },
      images: {
        legacy_and_trustees_of_family_history_and_values: [
          [
            'I want to use time meaningfully. My focus is on acting as a trustee to family history on behalf of generations to come. I want them to know who we were, how they got here and how they should live. I hope one day this will be something you consider thoughtfully.',
            '31',
          ],
          [
            'Genealogy or family history is important to me both for what it says and what it does not say. We need to remember our historic people; what they accomplished and why it matters',
            '35',
          ],
          [
            'In my opinion, family is an unbroken cycle of memories, gifts and talents and resources. A strand of valuable pearls. Do Your own part and keep the cycle prosperous and unbroken.',
            '22',
          ],
          [
            'The lesson from ancient trees and forests is that what we see today was planted as a small seed many years ago. Sometimes it may appear that our efforts are small and insignificant, but we should never doubt the power of time and providence to make something immense from our small efforts',
            '16',
          ],
          [
            'The grand old family house is a place where divine providence, history, events and people meet. It is absolutely critical that historic family assets should not be sold. No price can equal their real value. In instance where a sale is inevitable, the house must have its history exported as labelled artifacts, photos and memorabilia. The "new house" should have these arifacts meaningfully embedded so that our story continues uninterrupted. ',
            '51',
          ],
        ],
        compassion_and_social_impact_and_justice: [
          [
            'Life has taught me that wealth is a cycle and unless it is shared with the poor and defenceless, it will dry up at the same rate that our hearts become hard and unyielding. Our family has received a heavenly mandate to care for the less fortunate by creating self sustaining systems.Do not dismiss or ignore the call. Do not tokenize it - for in your compassion lies our future good. ',
            '18',
          ],
          [
            'I have lived in countries with different levels of social inequality and justice. I have been tempted to look the other way. To say "This is not my business" but at all such times, my conscience has pricked me. It is my belief that we must work to make the world and especially our societies more just and equitable. We must have answer the question "who is my neighbour". If we do not do this, our society will be left in the hands of wicked and unjust men and the war on the dispossessed will eventually come to our door steps.',
            '24',
          ],
          [
            'I tried in my own little way, and I want you to also inspire your children and grandchildren to be useful members of society. We must not only take, we must give back. Frequently ask them what they want to be when they grow up and do not steer them towards your definition of success. Success can include the diligent nurse, teacher or even postman. Think less of societal expectations and think impact.',
            '42',
          ],
          [
            'Those in need are often been stripped of their self respect. This is the first thing we must restore. A man or woman who does not have self respect is unlikely to receive the emotional energy to to be independent and useful members of society. In all that we do for the poor - we must not deny or reduce their sense of  "imageo dei" made in Gods image. ',
            '40',
          ],
          [
            'Do not let the faces of the poor repel you. Rather see in their sufferings, the sufferings and nobility of Christ who similarly suffered. We must not let the well coifed hair and done of nails, elegant clothes and perfumery of our wealthy associates be our only emblem of success. The poor battle heavy odds and they are often more hard working that the entitled rich.',
            '41',
          ],
          [
            'My faith has been important to me. It has been a light to guide, a rock to hid win times of danger and an anchor when I felt overwhelmed. I completely recommend Gods word - The Bible to you for comfort, direction and yes, also for rebuke.',
            '38',
          ],
        ],
        encouragement: [
          [
            'It is the responsibility of the older and more experienced member to put an arm around the younger generation when they are discouraged and disconsolate as well as to encourage them to take that extra step when they feel they can do no more. Family leaders must embrace these three responsibilities; to seek for the highest performance from the family team, as well as encourage collaboration between members by providing a safe, minimal judgement environment. Finally, they must benchmark individual benchmarks and encourage the family team member to compete against the benchmark and not one another. An overall positive atmosphere of encouragement is your responsibility.',
            '19',
          ],
          [
            'I have found that in large families, the weak and the young can be sidelined and conversations with them postponed indefinitely. Yet there is so much good in having time for the young and for associating with the weak. When siblings do that it is extremely powerful and I have some recommendations toward that end. ',
            '20',
          ],
          [
            'In every family - including ours, there is a tendency for people of like interests to band together. Society supports this type of atomization but we must not. I want to encourage you to encourage and support people with very different outlooks and character. This is especially powerful when it happens between people with different backgrounds and personal characteristics. There is a growth of our capacity to understand and care for others that needs to happen in order for us to remain "internally" wealthy. ',
            '5',
          ],
          [
            'Partings are an inevitable part of life. We understandably hate them and do everything to avoid them. Neverthless partings free individual members to become all that they are meant to be. Partings also herald a reunion of people who have experienced growth while alone or in a difference time and space. . All partings are temporary - even the parting of death which appears to be final. We must live in perpetual hope of an eventual reunion. Partings create space for family members to grow and they represent a vital part of making our family stronger. In as much as we hate the parting, let us keep in mind the tremendous gain that will be ours when inevitably we are reunited. Let us instead treasure these moments and use them to become wiser and stronger. the moments of absence will give us clarity, space and opportunity. If these opportunities are seized our reunion will be all the more sweeter.',
            '3',
          ],
          [
            'Fostering and nurturing the younger members of the family will mean spending time alone with them. This is an intentional moment which if crafted with grace,  allows the child to see themselves as a unique and valued member of the family. I especially taking reccomend taking walks together. It was a feature of my younger days. Walking provides so many interesting points of conversation. It provides solitude - but not the solitude of being in a room together but of having ancient trees and scenery as companions and onlookers. This contrast between the very old terrain and the young is a meaningful coming of age moment that will remain long in the memory. ',
            '10',
          ],
        ],
        harmony_craftsmanship_and_entrepreneurship: [
          [
            'In every family there are leaders and our family is no different. However I would like our concept of leadership to differ from the commonly accepted sole leader who towers above everyone. I would suggest that in addition to a central coordinating leader, we embrace the idea of having leaders for every phase of family growth. Lets see ourselves as an orchestra, In an orchestra, there are many instruments and they take turns to lead but whatever they do can only have flavour when all the other musical pieces are playing and not when they are silenced. ',
            '2',
          ],
          [
            'I suggest that family members treat their occupations as an ancient craft containing mysterious secrets and insights for those who have completely submitted to its demands. This means many many hours of deliberate practice and experimentation. Like the potter we want to make things that are not only valuable but which also stand the test of time. We do not want to create products and services that end up in the rubbish heap after a few years. rather we want to build beauty that is handed down from generation to generation.',
            '11',
          ],
          [
            'As a family we want to maintain our values, belief and identity but at the same time we need to create the future on our own terms. This means that we must grow into a enterpreneurial family that somehow unleashes the creative power of its people. There is often a dynamic tension between the older generation that wishes to keep what is best about us and the younger generation who want to break new ground. Such tensions are inevitable but they do not need to be destructive. Each motive is valid. Both must learn how to work together - not simply through compromise which can result in an average family that is neither this nor that. rather we must embrace the fact the the future will be very different to what things are like now - yet at the same time there are some things that must never change such as integrity, sibling love anhd so on. ',
            '23',
          ],
        ],
        contemplation_meaning_and_transcendence: [
          [
            'It has been my experience, that there are great benefits to withdrawing from busyness and entering into a time of solitude and reflection. Solitude cleanses our soul from the daily debris of emotional highs and lows, hurts and the pressures of living - especially for those living in cities or those with fast paced lives. We all have a fear of stopping. Somehow we come to believe that everything depends on us and that stepping aside and away into reflection somehow means letting others down. The benefits of contemplation and reflection are well known - the dragon we need to slay is our addiction to the urgent and the fear that everything will unravel in our absence.',
            '4',
          ],
          [
            'One step greater than enjoying serenity is to immerse oneself in it with spirit, soul and, body. We were made from the clay, sowe have afinity with the elements of earth. Learn a skill that enables you interact with the earth; painting, pottery, fishing, or a hoby such as bird watching, butterfly photography. Dont be passive in solitude - engage with it and bring out something that did not exist you.',
            '1',
          ],
          [
            'Life is not easy but it can be enjoyable. It is a strange thing but to make progress, you will need to conquetr not just problems but yourself. To be distinctive, you will need to experience life in such a way that it brings out what is special and unique about you. You donty become something by joining yourself to these things or buying stuff. You become unique by taking the hard choices and fixing your eyes on the goal - so strongly, that you can endure the inconveniences and challenges of the moment. ',
            '26',
          ],
          [
            'It is good to have ambition - to aim to be the very best. This is how the great civilizations were made and defended. Men overcame the challenges of a hostile, foreign terrain and the enmity of others to build something magnificent and enduring. In building those realize that whatever you build must speak iof something greater than yourself.',
            '27',
          ],
          [
            'Nothing is as humbling as looking into a star filled sky. Knowing our smallness against the canvas of the universe can help us find the humility we need to live outstanding lives. You see, a proud person receives input only from very few people they consider worthy. A humble person on the other hand is made rich by the multiple inputs they observe, relate and learn from. The humility of knowing our smallness against time is a deeply rewarding experience that you should create and stimulate in your children. ',
            '30',
          ],
        ],
        breaking_limits_insurgency: [
          [
            'In my experience, the greatest progress and innovation has come from people willing to come to the front of the queue and jump into situations that had other people backing off. These are the people that create new industries, paradigms, opportunities, products and services. Jumping in where "angels fear to thread" is difficult because human instinct is self preservation. Bucking this trend produces outstanding opportunity but for you or your children to achieve this will not be an accident. rather, it will be a well taught out process. ',
            '43',
          ],
          [
            'One of the drawbacks of a good education and breeding is that it can make us very self conscious and uptight - especially in situations that threaten our social status or credentials. Its so easy to feel like a fraud or an imposter. This is very common in highly educated people because their extensive reading and intelligence alerts them to gaps in their knowledge or abilities. Of course these gaps dont make them frauds - no one can know everything. It just makes them feel like frauds - which they arent. I am concerned that these feelings which are so common in a world where information is instantaneous will cripple you or my grandchildren. It is important they be addressed wherever they occur. ',
            '52',
          ],
          [
            'Its so important to know how to move forward in difficult moments. There are times in life when it seems easier to go backwards into what is familiar than to go forward into uncertainty. As a family, we have faced such moments before and we will definitely face them again. Knowing how to move forward in times of uncertainty is a huge skill. Being paralyzed by the expectations of parents, peer or even the history of family achievements is much more common than you think. I have a few ideas from the world of world class gymnasts that may prove useful.',
            '9',
          ],
          [
            'Now I want to talk a little about disability or limitations . All families have a measure of disability even when they act like all is well. Disabilities may include some festering feuds, difficulty in reaching agreements, weakness in decision making or a shameful family secret that erodes confidence. They might be structural - for instance difficulties between step children or cousins. Frequently they will involve a single or a group of overbearing family members who deprive other members of the space and incentives to grow. As a family we have had our own disabilities - areas of weakness that improperly addressed could invite catastrophy. We have encountered such challenges in the past and I want to share with you how we overcame them. ',
            '13',
          ],
        ],
        next_gen_grandchildren: [
          [
            'What can we impart to a child too young to understand or be understood? Do we wait until he/she comes of age? Certainly not. It is not the old and mature flame that requires support, it is the small and weak one. The youngest members of our family teach us an invaluable lesson - that we must nourish the weak and fragile. How we fan these flames into being is by showing them grace. They are still too young to earn our respect and approval so everything we give must be grace - completely unmerited grace in generous portions. ',
            '21',
          ],
          [
            'There is nothing that imparts wisdom and assurance in life as the apprenticeship of our next generation to their grand parents. This is where wisdom meets with an opened and trusting heart. I have read somewhere that meaningful interactions between educated grandparents and their grandchildren can improve their chances of financial success 4X as much the children interaction with their own parents! Grandparents love is pure in that it is not transactional - they are not motivated by a quid pro quo. They give in the purest way possible and somehow that creates the magic in the hearts of their grandchildren. I seriously urge that you foster this relationship.',
            '41',
          ],
          [
            'One of the things we used to love was playing with our siblings or even better our cousins. Those times are etched in our memory and they laid the foundation for our future family team work, communication and trust. These are skills that siblings and cousins will need when interacting over the family business or its investments. You will need them to have developed this strong sense of camaraderie when you are older and now need to cede responsibility to them. Play time with children is not wasted, most of their valuable lessons are learned through play. ',
            '20',
          ],
          [
            'Encourage grandparents to show their love. Some grand parents are reserved but all are loving. There is nothing that stands in a childs memory as being loved extravagantly in public. There will come a time in the teens when they will temporarily dislike such open shows of affection especially in front of their peers. But before the teen years, they absolutely relish it. Sitting on a knee, being held or carried - any display of love that involves elongated periods of touch warm the heart and open it up to knowledge and love.',
            '17',
          ],
        ],
        family_celebration_and_togetherness: [
          [
            'There are few things better than watching one of our own being honoured. It validates our culture, our abilities and also provides great encouragement to do more better. In addition, it creates additional points of reference for our next generation; they have one more star to travel by, one more leader that inspires. This is especially so when the achievements come through great sacrifice and effort. How can we make deep gains from the achievements of members of our family.',
            '45',
          ],
          [
            ' All birthdays are worth celebrating but the birthdays of the older generations are especially so. Older generations carry within them the history and values of the family. We respect their years, their endurance and sacrifice. Their birthdays are one occasion in which we can all come together to recognize and honour these pillars of our family. Such birthdays should not, even where the older family member is self effacing, be taken for granted or dismissed. rather they must become a rallyimg point for multiple generations of our family members. Because if we honour them, we shall in turn be honoured. ',
            '46',
          ],
          [
            'There is a saying that a family that plays together stays together. Family interactions - especially those that involve memorable places plus physical exertion can release body chemicals that help people bond together. There is anecdotal evidence that troops that have come under enemy fire bond at a deeper level that troops that have not had such experiences. So I wholeheartedly reccomend that our family goes on adventures to places we are unfamiliar with and which will promote both conversational as well as physical  and mental challenge and exercision. Such activities will stimulate our health and intellectual capital and they should be treated with the same seriousness as an investment board meeting. I know that this will be hard to do. But do try to remember that this is where our wealth is both created and reflected - it is our factory and our mirror. The better we do this, the brighter our future will be. ',
            '36',
          ],
          [
            'There is nothing quite like a wedding to set family pulses racing. Weddings are important parts of our family life. They mark the beginning of something new that we can see but not yet fully grasp. For some families, marriage marks the splintering of the family tree - but this is not our hope or expectation for our family marriages. In our family, marriage is both additions and expansion It is  never a subtractions. How the family has conducted themselves in the past; whether we see ourselves as a "nest" or as a "team" will determine whether it is a splintering of the tree or a thickening of the tree.',
            '7',
          ],
          [
            'We need to encourage interaction between the next generation - especially at cousin level. Such events should be a constant in our family calendar. It is important members of this generation get to know each other well before they step into the fullness of adult responsibilities. A lot of distrust and communication failure occurs at the cousin level  where family ties and affinity feel looser. If you are to do this well, I suggest separate meetings for pre teens, one for the teenagers and another for young adults. Make these occasions fun, educative and social and watch the next generation bloom into brighter flowers than the previous one. ',
            '50',
          ],
        ],
      },
      filteredImages: {},
    }
  },
  created() {
    let lessonsStoredData = storage.getDataFromLocalStorage('lessons')

    Object.entries(lessonsStoredData).forEach(([key]) => {
      if (lessonsStoredData[key]) {
        this.filteredImages[key] = this.images[key]
      }
    })

    let storedData = storage.getDataFromLocalStorage('images')
    if (storedData) {
      this.selectedImage = storedData
    }
  },
  methods: {
    routeToNextPage() {
      storage.storeDataToLocalStorage('images', this.selectedImage)
      this.$router.push({ name: 'Regrets' })
    },

    deSlug(text) {
      return deSlug(text)
    },
  },
  computed: {
    validateInput() {
      let p = 0
      for (let i in this.selectedImage) {
        if (this.selectedImage[i] !== '') {
          p += 1
        }
      }
      return p >= 3
    },
  },
}
</script>
