<template>
  <div class="progress">
    <div class="bar" :style="{ width: `${percent}%` }"></div>
  </div>
</template>
<script>
export default {
  name: 'ProgressBar',
  props: {
    percent: {
      type: Number,
      required: true,
    },
  },
}
</script>
