export function deSlug(slug) {
  if (!slug) return ''

  let words = slug.split('_')

  for (let i = 0; i < words.length; i++) {
    let word = words[i]
    words[i] = word.charAt(0).toUpperCase() + word.slice(1)
  }

  return words.join(' ')
}

export function fixCamelCase(string) {
  return string.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export function convertToSlug(word, separator = '_') {
  return word
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, separator)
}
